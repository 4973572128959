.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  text-align: center;
}

.how-this-works {
  background-color: #ffffff;
}

.edit-display {
  overflow-y: scroll;
  background-color: #ffffff;
  height: 100%;
}

.edit-input {
  height: 500px;
}

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: 3.5rem; /* Footer height */
}

.footer {
  background-color: #2c3e50;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem; /* Footer height */
  text-align: center;
}

.header {
  background-color: #2c3e50;
  color: #ffffff;
}

.nav-link-inactive {
  text-decoration: none;
  color: white;
}

.nav-link-inactive:hover {
  text-decoration: none;
  color: white;
}

.signup-button {
  vertical-align: bottom;
}
